<template>
  <div class="ContactUs">
    <div class="left">
      <img src="../assets/image/041.png" class="tu01" />
      <p> EMAIL:
        <br />
        info@kolboxsocial.com
      </p>
      <p>
        TEXT:
        <br />213-259-3638
      </p>
    </div>
    <div class="right">
      <img src="../assets/image/042.png" />
      <div class="tijiao">
        <div>
          <p>FULL NAME*</p>
          <input type="text" class="an01" v-model="state.fullName" />
        </div>
        <div style="margin-left: 28px;">
          <p>EMAIL*</p>
          <input type="text" class="an02" v-model="state.email" />
        </div>
        <div class="tishi" :class="{success:state.success}" v-show="state.showTishi">{{state.tishi}}</div>
        <div>
          <p>MESSAGE*</p>
          <input type="text" class="an03" v-model="state.message" />
        </div>
        <button class="submit" @click="submit">SUBMIT</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    reactive
  } from 'vue'
  import axios from 'axios'
  export default {
    setup() {
      const state = reactive({
        fullName: '',
        email: '',
        message: '',
        tishi: '',
        showTishi: false,
        success: false
      })
      const isEmailAvailable = (emailInput) => {
        var myreg = /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/
        if (!myreg.test(emailInput)) {
          return false
        } else {
          return true
        }
      }
      const submit = async () => {
        const {
          fullName,
          email,
          message
        } = state
        let count = 0
        state.success = false
        if (message.trim().length < 1) {
          state.tishi = 'Message cannot be empty'
          count++
        }
        if (!isEmailAvailable(email)) {
          state.tishi = 'E-mail format is incorrect'
          count++
        }
        if (email.trim().length < 1) {
          state.tishi = 'Email cannot be empty'
          count++
        }
        if (fullName.trim().length < 1) {
          state.tishi = 'Full name cannot be empty'
          count++
        }
        if (count > 0) {
          state.showTishi = true
          setTimeout(() => {
            state.showTishi = false
          }, 2000)
          return
        }
        const {
          data
        } = await axios.post('https://p.kolbox.com/kol-api/storecustomermessage/storeCustomerMessage/add', {
          fullName,
          email,
          message
        })
        console.log(data)
        if (data.success) {
          state.tishi = 'Submitted successfully'
          state.success = true
          state.fullName = ''
          state.email = ''
          state.message = ''
        } else {
          state.tishi = 'Submission Failed'
        }
        state.showTishi = true
        setTimeout(() => {
          state.showTishi = false
        }, 2000)
      }
      return {
        state,
        submit
      }
    }
  }

</script>

<style scoped lang="less">
  .ContactUs {
    width: 1200px;
    height: 800px;
    background-color: rgb(255, 255, 255);

    .left {
      width: 400px;
      height: 800px;
      float: left;

      .tu01 {
        margin-top: 150px;
      }

      p {
        font-size: 26px;
        color: #000;
        margin-top: 50px;
        font-weight: 700;
      }
    }

    .right {
      width: 700px;
      height: 800px;
      background-color: rgb(255, 255, 255);
      float: right;

      .tijiao {
        width: 700px;
        height: 400px;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;

        p {
          color: #000;
          font-size: 20px;
        }

        input {
          width: 334px;
          height: 70px;
          margin-top: 10px;
          color: #000000;
          font-size: 20px;
          border: 1px solid #000;
          padding: 0 20px;
          font-size: 18px;
          box-sizing: border-box;
        }

        .an03 {
          width: 700px;
        }

        .tishi {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 300px;
          height: 50px;
          overflow: hidden;
          background: rgb(233, 105, 105);
          font-size: 18px;
          line-height: 50px;
          text-align: center;
          color: #fff;
          border-radius: 10px;
        }

        .success {
          background: #47b13e;
        }

        .submit {
          cursor: pointer;
          width: 700px;
          height: 84px;
          margin-top: 30px;
          background-color: #f7323f;
          font-size: 24px;
          color: rgb(255, 255, 255);
          border: 2px #000;
        }
      }
    }
  }

</style>
